/********************************************

    START HOME PAGE SECTIONS CONTENT

********************************************/

.nav-sections-wrapper {
	position: relative;
}
.sidenav {
	display: none;
	position: fixed;
	z-index: 5;
	top: 45%;
	right: 5%;
	background: transparent;
}
@media (min-width: 992px) {
	.sidenav {
		display: block;
	}
}
@media (min-width: 1200px) {
	.sidenav {
		top: 50%;
	}
}
.section-ttl {
	font-size: 16px;
	color: #844439;
}
.nav-ul {
	position: relative;
}
.nav-ul:before {
	content: "";
	display: block;
	position: absolute;
	height: calc(100% - 30px);
	width: 2px;
	background: #e5b4b2;
	left: 28px;
	top: 14px;
}
.nav-ul a {
	position: relative;
	display: block;
	color: #242424;
	font-size: 14px;
	font-family: Lato-LightItalic, sans-serif;
	padding: 7px 0;
	&.active {
		&:before {
			background: white;
		}
	}
}
.nav-ul a:hover:before {
	background: white;
}
.nav-ul a:before {
	content: "";
	display: inline-block;
	width: 13px;
	height: 13px;
	border: 2px solid #e5b4b2;
	background: #e5b4b2;
	border-radius: 7px;
	margin: 0 5px 0 23px;
}
main .tasting-notes-container {
	max-width: none;
	padding: 0;
}
main .wine-icon {
	background: url("/public/images/svgs/WineGlass.svg") center no-repeat;
	margin: auto;
	height: 80px;
}
main .grapes-icon {
	background: url("/public/images/svgs/Grapes.svg") center no-repeat;
	margin: auto;
	height: 80px;
}
main .barrel-icon {
	background: url("/public/images/svgs/WineBarrel.svg") center no-repeat;
	margin: auto;
	height: 80px;
}
.welcome-to-rosehaven {
	margin-top: 40px;
	h1 {
		font-size: 40px;
		@media (min-width: $md) {
			font-size: 70px;
		}
	}
	p {
		font-family: 'Lato-Regular';
		//width: 100%;
		//margin: auto;
		font-size: 16px;
		@media (min-width: $sm) {
			//width: 50%;
		}
		@media (min-width: $md) {
			font-size: 25px;
		}
	}
}
.main-section {
	margin: 155px 0px;
	@media(min-width: $lg) {
		padding: 0px 180px;
	}
	.col-left {
		padding: 0px;
		margin-right: 60px;
	}
	.col img {
		width: 100%;
		height: auto;
	}
}
.subsection {
	text-align: center;
}
.tasting-notes a {
	display: inline-block;
}
.subsection h3, .subsection a {
	font-family: 'Lato-Regular';
}
.subsection p {
	font-family: 'Lato-Light';
}
main h1,
.subsection h2 {
	font-family: 'MrsEavesOT-Roman';
	color: #844439;
}
.subsection h4, .subsection h5 {
	font-family: 'MrsEavesOT-Italic';
}
.wine-making {
	ul {
		list-style-type: none;
		padding-left: 0px;
		li {
			display: inline-block;
			margin: 0px 15px !important;
			width: 40%;
		}
	}
}
main .wine-icon, main .grapes-icon, main .barrel-icon {
	margin-top: 100px;
}