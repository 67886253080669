.anchor {
	top: -90px;
	position: relative;
}
.square {
	&.square-16by9 {
		&:before {
			padding-top: 42.52%;
		}
	}
	&:before {
		content: "";
		float: left;
		padding-top: 100%;
	}
	&:after {
		content: "";
		clear: both;
		display: block;
	}
}
.page-banner {
	height: 200px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	@media(min-width: $collapse){
		height: 400px;
	}
}

.bg-main {
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	background-image: url('../images/bg-main.jpg');
}