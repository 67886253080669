/********************************************

    START COMMON STYLES

********************************************/

.rose-border {
	width: 50px;
	background-color: #e5c8c6;
	height: 1px;
}
.rose-border-sm {
	width: 25px;
	background-color: #e5c8c6;
	height: 1px;
}
.rose-v-border {
	background-color: #e5c8c6;
	height: 1px;
}
.lato-regular {
	font-family: Lato-Regular;
}
.lato-light {
	font-family: Lato-Light;
}
/************ Default Buttons CTAs *************/
.btn-default {
	font-family: Lato-Regular, sans-serif;
	font-size: 16px;
	color: $dark;
	text-align: center;
	border: 2px solid $pink;
	background: white;
	cursor: pointer;
	transition: 300ms;
	border-radius: 0;
	padding: .375rem 3rem;
	@media (min-width: $sm) {
		padding: 0.475rem 5rem;
	}
	&:hover, &:focus, &:active {
		//min-width: 250px;
		background: $pink;
		border: 2px solid $pink;
	}
}
.age-gate-btn-text {
	color: black;
	font-size: 18px;
	line-height: 55px;
	text-align: center;
	text-decoration: none !important;
	display: block;
	font-family: Lato-Regular, sans-serif;
}
/********************************************

    START GLOBAL NAV

********************************************/

// nav.navbar {
// 	background-color: white;
// 	height: 90px;
// 	text-align: center;
// 	color: black;
// }
// a.email-link {
// 	color: #844439;
// 	text-decoration: underline;
// 	font-family: 'Lato-Regular', sans-serif;
// }
// a.nav-link {
// 	color: black;
// }
// ul.navbar-nav.mr-auto {
// 	margin-right: 0 !important;
// }
// nav.navbar .navbar-toggler {
// 	cursor: pointer;
// 	background-image: url("../images/hamburger.png");
// 	background-size: 100%;
// 	padding: 10px;
// }
// nav.navbar .navbar-toggler[aria-expanded="true" ] {
// 	cursor: pointer;
// 	background-image: url("../images/close.png");
// 	background-size: 100%;
// 	height: 60px;
// }
// nav.navbar hr.rose-border-sm {
// 	display: none;
// }
/* the pink bar that separates nav links */
// .nav-item.vertical-pipe {
// 	border-right: 1px solid $pink;
// }
/* logo with link to home page */
// .nav-logo-link {
// 	display: block;
// 	width: 240px;
// 	// height: 26px;
// 	margin: 0 auto;
// 	@media(min-width: $md) {
// 		width: 365px;
// 	}
// }
// .nav-logo-link img {
// 	height: 100%;
// }
/********************************************

    START GLOBAL FOOTER

********************************************/

footer {
	background-color: #f8f8f8;
	text-align: center;
}
footer .logo {
	height: 75px;
	background: url("/public/images/svgs/Rosehaven_Logo1.svg") center no-repeat;
	width: 330px;
	background-size: 100%;
	margin: auto;
}
footer a {
	display: inline-block;
	color: $dark;
	height: 13px;
	line-height: 13px;
}
footer a:hover {
	cursor: pointer;
	color: $dark;
}
.links-container {
	width: auto;
	display: inline-block;
}
.links-container:before {
	content: "";
	width: 50%;
	display: block;
	border-top: 1px solid $dark;
	padding: 0 10px 17px;
	margin: 0 auto;
	border-top: 1px solid $dark;
}
@media (min-width: 768px) {
	.links-container:before {
		width: 110%;
		margin-left: -15px;
	}
}
.footer-nav-link {
	text-transform: uppercase;
}
.footer-nav-link:not(:last-child),
.lowercase-link:not(:last-child) {
	border-right: 1px solid $dark;
	padding-right: 10px;
	margin-right: 5px;
}
.footer-link {
	text-transform: uppercase;
	font-size: 13px;
	text-decoration: underline;
}
.footer-link:not(:last-child) {
	margin-right: 10px;
}
.row-2-links {
	margin: 20px 0 40px 0;
}
.legal-pages-links {
	padding-bottom: 25px;
	width: 92%;
	margin: 0 auto;
}
footer .copyright {
	color: $dark;
	font-size: 12px;
	margin: 10px 0 0;
}
.line-break {
	display: block;
}
@media (min-width: 768px) {
	.line-break {
		display: none;
	}
}
/* For age gate footer: */
@media (min-width: 768px) {
	.fixed-footer {
		position: fixed;
		width: 100%;
		bottom: 0;
	}
}
/* Main Container ----------- */
main {
	background-color: white;
	padding: 0px 15px;
	margin-top: 60px;
	min-height: calc(100vh - 380px);
	@media(min-width: $sm) {
		padding: 0px 25px;
	}
	@media(min-width: $collapse){
	min-height: calc(100vh - 370px);
		margin-top: 90px;
	}
}
.background-hero {
	background-size: cover;
	background-image: url("../images/hero_image.jpg");
	z-index: 10;
	position: relative;
	.hero-wrapper {
		max-width: 650px;
		margin-left: 20%;
		p {
			max-width: 300px;
		}
	}
	h3 {
		font-size: $size45;
		@media(min-width: $md) {
		}
	}
	h2 {
		font-size: $size45;
		@media(min-width: $md) {
		}
	}
}
.background-hero h3,
.hero-wrapper-mobile h3 {
	font-family: "MrsEavesOT-Italic";
	color: #e5c8c6;
}
.background-hero h2,
.hero-wrapper-mobile h2 {
	color: #000;
	//font-size: 2em;
	font-family: "Lato-Light";
	color: white;
}
.background-hero p,
.hero-wrapper-mobile p {
	font-family: "Lato-Light";
	color: white;
	margin: auto;
}
/********************************************

    START WHERE TO FIND PAGE

********************************************/
.container.where-to-find {
	max-width: 540px;
}
.where-to-find .row {
	width: 100%;
}
.where-to-find .col-12 {
	padding: 0;
	text-align: center;
}
.where-to-find .rose-image-box img {
	height: 100%;
	width: 100%;
}
@media (min-width: 992px) {
	.container.where-to-find {
		max-width: 1140px;
	}

	main .where-to-find {
		background: url('../images/xray_rose.jpg') no-repeat 95%;
		background-size: auto 348px;
		min-height: 500px;
	}

	.where-to-find .rose-image-box {
		display: none;
	}
}
@media (min-width: 1200px) {
	.container.where-to-find {
		background: url('../images/xray_rose.jpg') no-repeat 92% 100%;
		background-size: auto 415px;
		min-height: 550px;
		max-width: 1200px;
	}

	.where-to-find .row {
		margin-top: 120px;
	}
}
.where-to-find {
	max-width: none;
}
//.where-to-find h1 {
//	text-align: center;
//	font-size: 70px;
//}
.where-to-find h2 {
	font-family: 'MrsEavesOT-Roman';
	color: #844439;
}
.where-to-find h3 {
	font-family: 'Lato-Regular';
	font-size: 20px;
}
.where-to-find p {
	font-size: 20px;
}
@media only screen and (max-width: 768px) {

	/* Main Mobile ----------- */
	main .col {
		flex-basis: initial;
	}
	.hero-wrapper-mobile h2,
	.hero-wrapper-mobile h3,
	.hero-wrapper-mobile p {
		color: black;
		text-align: center;
	}
	.hero-wrapper-mobile h3 {
		color: #844439;
	}

	.main-section {
		padding: 0px;
		margin: 0px;
	}
	/* Where To Find Mobile ----------- */
	.where-to-find h1 {
		margin-bottom: 135px;
	}
	.rose-container img {
		width: 100%;
	}
	/* Contact Mobile ----------- */
	.contact-copy p {
		text-align: center;
	}
	label.contactusField {
		font-family: 'MrsEavesOT-Roman';
		display: block;
		width: 100%;
		text-align: center;
		font-size: 24px;
	}
	#contactUsForm {
		@media(min-width: $md) {
			padding: 0px 62px;
		}
	}
	.contact-copy, #contactUsForm {
		margin: 0px;
		width: 100%;
	}
}