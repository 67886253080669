.footer {
	.back-to-top {
		position: absolute;
		right: 0;
		top: -50px;
		width: 50px;
		height: 50px;
		background-color: darken($gray,10);
		color: $light;
		font-size: $size30;
		line-height: 50px;
		text-align: center;
	}
}