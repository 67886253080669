#dobDropDownWrapper {
	position: relative;
}
#dobDropDownWrapper, #dobDay, #dobYear {
	display: inline-flex;
}
#dobLabel {
	display: block;
}
#dobMonth {
	& ~ .parsley-errors-list {
		left: 0;
		top: 38px;
	}
}
#dobDay {
	width: 60px;
	& ~ .parsley-errors-list {
		left: 143px;
		width: 60px;
	}
}
#dobYear {
	width: 70px;
	& ~ .parsley-errors-list {
		left: 214px;
		width: 60px;
	}
}
.legal {
	min-height: 100%;
	height: 100%;
	.rosehaven-logo-legal {
		max-width: 400px;
	}
	.main {
		padding-bottom: 100px;
	}
	footer {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

#ejgContactUs .desktop {
	display: block!important;
}

/********************************************

    CONTACT FORM

********************************************/
.contact-copy {
	width:50%;
	margin:auto;
	h1 {
		margin-top:45px;
		text-align:center;
	}
	p {
		font-family: 'Lato-Light';
	}
	hr {
		margin: 0 auto 40px;
	}
}

#contactUsForm {
	h2 {
		font-family: 'MrsEavesOT-Roman';
		text-align:center;
		color: #844439;
	}

	.form-control, .custom-select {
		background-color: #f8f8f8;
		border:none;
		height:60px;
	}
}


/*#contactUsForm .dropdownWrapper {
	width: 100%;
}*/

#productHeaderWrapper {
	font-family: "Lato-Light";
}



label.contactusField {
	font-family: 'MrsEavesOT-Roman';
}

/*#contactUsForm input#submit {
	display: block;
	width: 280px;
	height: 57px;
	margin-right: auto;
	margin-left: auto;
	border: 1px solid pink;
	text-decoration: none;
	background-color:transparent;
	font-family:Lato-Regular;
	font-size:24px;
	&:hover {}
}*/

#prefContactMethodWrapper input{
	display:inline-block;
}