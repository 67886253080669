// Why not mobile first?

// @media only screen and (max-width : $lg - 1) {
// 	/* Nav Mobile ----------- */
// 	nav.navbar{
// 		padding:0px;
// 	}
// 	.navbar-collapse.collapse.show {
// 		background: url("../images/xray_rose.jpg") center no-repeat;
// 		width:100%;
// 		margin-left:0px;
// 		height: 100%;
// 		position:fixed;
// 		top:90px;
// 	}
// 	ul.navbar-nav {
// 		background-color: #fbeee5;
// 		height:100%;
// 	}
// 	.nav-link {
// 		font-size:40px;
// 		padding:15px 0px;
// 	}
// 	ul li:nth-child(3) {
// 		margin-bottom:95px;
// 	}
// 	ul li:nth-child(4) {
// 		margin-top:95px;
// 	}
// }



.navbar {
	padding: 0;
	height: 60px;
	@media(max-width: $collapse - 1){
		justify-content: center;
	}
	@media(min-width: $collapse){
		height: 90px;
	}
	.navbar-brand {
		margin: 0;
		img {
			height: auto;
			width: 200px;
			@media(min-width: $collapse){
				width: 365px;
			}
		}
	}
	.navbar-toggler {
		position: absolute;
		left: 0;
		cursor: pointer;
		border-radius: 0;
		border: 0;
		padding: 15px 9px ;
		// padding: 13px 9px 18px;
		right: 0;
		transition: background 250ms;
		&:hover {
			background-color: transparent;
		}
		&:focus {
			outline-color: transparent;
			background-color: transparent;
		}
		// &:after {
		// 	content: "MENU";
		// 	font-size: $size11;
		// 	position: absolute;
		// 	text-align: center;
		// 	top: 72%;
		// 	right: 0;
		// 	left: 0;
		// 	font-weight: 700;
		// } 
		&[aria-expanded="true"] {
			// &:after {
			// 	content: "CLOSE";
			// }
			.icon-bar {
				opacity: 0;
			}
			.icon-bar-x {
				opacity: 1;
				left: 0;
				transform: rotate(45deg);	
				transition: 250ms 150ms;
			}
			.icon-bar-sneaky {
				transform: rotate(-45deg);
			}
		}
		.icon-bar {
			position: relative;
			background-color: $red;
			width: 32px;
			height: 2px;
			opacity: 1;
			transform: rotate(0deg);
			transition: 250ms;
			top: 0;
			display: block;
			margin-top: 6px;
			&:first-child {
				margin-top: 0;
			}
			&.icon-bar-sneaky {
				margin-top: -2px;
			}
			&:nth-child(4) {
				margin-bottom: 1px;
			}
		}
		.icon-bar-x {
			left: 5px;
		}
	}
	.main-menu {
		flex-basis: 100%;
		@media(max-width: $collapse - 1){
			position: fixed;
			top: -240px;
			right: -100%;
			bottom: 0;
			overflow: auto;
			width: 100%;
			background-color: #fbeee5;
			transition: opacity 500ms, right 0ms 500ms, top 500ms;
			opacity: 0;
			background-image: url('../images/bg-menu-flower-full.png');
			background-position: bottom right;
			background-repeat: no-repeat;
			// background-size: contain;
			&.menu-open {
				right: 0;
				opacity: 1;
				top: 60px;
				transition: opacity 750ms, top 500ms;
			}
		}
	}
	.navbar-nav {
		@media(max-width: $collapse - 1){
			text-align: center;
		}
	}
	.nav-link-anchor {
		text-transform: uppercase;
	}
	.nav-item {
		@media(min-width: $xl){
			padding: 0 5px;
		}
	}
	.nav-item-pipe {
		@media(max-width: $collapse - 1){
			border-bottom: 2px solid $pink;
			width: 30px;
			margin: 20px auto;
		}
		@media(min-width: $collapse){
			border-right: 2px solid $pink;
			padding: 0;
		}
	}
	.nav-link {
		padding: 5px 10px;
		font-family: Lato-Regular, sans-serif;
		@media(max-width: $collapse - 1){
			font-size: $size24;
			padding: 15px 10px;
		}
	}
}