body {
	color:black;
}

a {
	color:black;
	text-decoration: none;
}

a:hover,
.nav-ul a:hover,
.nav-link:hover {
	cursor: pointer;
	color: #242424;
	text-decoration: none;
}

//div {
//	box-sizing: content-box;
//}

.custom-select:focus, .form-control:focus {
	border-color: $pink;
	box-shadow: inset 0 1px 2px rgba(0,0,0,.075), 0 0 5px transparentize($pink, .01);
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.custom-radio .custom-control-input:checked~.custom-control-label::before {
	background-color: $red;
}

.custom-control-input:focus~.custom-control-label::before {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem transparentize($pink, .75);
}

.custom-control-input:active~.custom-control-label::before {
	background-color: $pink;
}