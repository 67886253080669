$red: #844439;
$dark: #242424;

$light: #ffffff;
$gray: #f8f8f8;

$pink: #E5B4B2;
$green: #6b6f20;
$gold: #cd9f2b;

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$collapse: $lg;

//social network colors & Font Awesome Codes
$facebook: #3b5998;
$facebookContent: "\f230";
//<i class="fa fa-facebook-official" aria-hidden="true"></i><span class="sr-only">Facebook</span>
$twitter: #55acee;
$twitterContent: "\f099";
//<i class="fa fa-twitter" aria-hidden="true"></i><span class="sr-only">Twitter</span>
$google: #dd4b39;
$googleContent: "\f0d5";
//<i class="fa fa-google-plus" aria-hidden="true"></i><span class="sr-only">Google+</span>
$youtube: #bb0000;
$youtubeContent: "\f167";
//<i class="fa fa-youtube" aria-hidden="true"></i><span class="sr-only">YouTube</span>
$linkedin: #007bb5;
$linkedinContent: "\f0e1";
//<i class="fa fa-linkedin" aria-hidden="true"></i><span class="sr-only">LinkedIn</span>
$instagram: #125688;
$instagramContent: "\f16d";
//<i class="fa fa-instagram" aria-hidden="true"></i><span class="sr-only">Instagram</span>
$pinterest: #bd081c;
$pinterestContent: "\f0d2";
//<i class="fa fa-pinterest" aria-hidden="true"></i><span class="sr-only">Pinterest</span>
$rss: #ff6600;
$rssContent: "\f09e";
//<i class="fa fa-rss" aria-hidden="true"></i><span class="sr-only">RSS Feed</span>

// @keyframes fadeIn {
//   0%   { opacity: 0; }
//   100% { opacity: 1; }
// }

// @mixin fadeIn($speed: 750ms) {
// animation:         fadeIn $speed; /* IE 10+, Fx 29+ */
// }

// .fadeIn {
//   @include fadeIn;
// }

$size1: 0.0625rem;
$size2: 0.125rem;
$size3: 0.1875rem;
$size4: 0.25rem;
$size5: 0.3125rem;
$size6: 0.375rem;
$size7: 0.4375rem;
$size8: 0.5rem;
$size9: 0.5625rem;
$size10: 0.625rem;
$size11: 0.6875rem;
$size12: 0.75rem;
$size13: 0.8125rem;
$size14: 0.875rem;
$size15: 0.9375rem;
$size16: 1rem;
$size17: 1.0625rem;
$size18: 1.125rem;
$size19: 1.1875rem;
$size20: 1.25rem;
$size21: 1.3125rem;
$size22: 1.375rem;
$size23: 1.4375rem;
$size24: 1.5rem;
$size25: 1.5625rem;
$size26: 1.625rem;
$size27: 1.6875rem;
$size28: 1.75rem;
$size29: 1.8125rem;
$size30: 1.875rem;
$size31: 1.9375rem;
$size32: 2rem;
$size33: 2.0625rem;
$size34: 2.125rem;
$size35: 2.1875rem;
$size36: 2.25rem;
$size37: 2.3125rem;
$size38: 2.375rem;
$size39: 2.4375rem;
$size40: 2.5rem;
$size41: 2.5625rem;
$size42: 2.625rem;
$size43: 2.6875rem;
$size44: 2.75rem;
$size45: 2.8125rem;
$size46: 2.875rem;
$size47: 2.9375rem;
$size48: 3rem;
$size49: 3.0625rem;
$size50: 3.125rem;
$size51: 3.1875rem;
$size52: 3.25rem;
$size53: 3.3125rem;
$size54: 3.375rem;
$size55: 3.4375rem;
$size56: 3.5rem;
$size57: 3.5625rem;
$size58: 3.625rem;
$size59: 3.6875rem;
$size60: 3.75rem;
$size61: 3.8125rem;
$size62: 3.875rem;
$size63: 3.9375rem;
$size64: 4rem;
$size65: 4.0625rem;
$size66: 4.125rem;
$size67: 4.1875rem;
$size68: 4.25rem;
$size69: 4.3125rem;
$size70: 4.375rem;
$size71: 4.4375rem;
$size72: 4.5rem;
$size73: 4.5625rem;
$size74: 4.625rem;
$size75: 4.6875rem;
$size76: 4.75rem;
$size77: 4.8125rem;
$size78: 4.875rem;
$size79: 4.9375rem;
$size80: 5rem;
$size81: 5.0625rem;
$size82: 5.125rem;
$size83: 5.1875rem;
$size84: 5.25rem;
$size85: 5.3125rem;
$size86: 5.375rem;
$size87: 5.4375rem;
$size88: 5.5rem;
$size89: 5.5625rem;
$size90: 5.625rem;
$size91: 5.6875rem;
$size92: 5.75rem;
$size93: 5.8125rem;
$size94: 5.875rem;
$size95: 5.9375rem;
$size96: 6rem;
$size97: 6.0625rem;
$size98: 6.125rem;
$size99: 6.1875rem;
$size100: 6.25rem;
$size101: 6.3125rem;
$size102: 6.375rem;
$size103: 6.4375rem;
$size104: 6.5rem;
$size105: 6.5625rem;
$size106: 6.625rem;
$size107: 6.6875rem;
$size108: 6.75rem;
$size109: 6.8125rem;
$size110: 6.875rem;
$size111: 6.9375rem;
$size112: 7rem;
$size113: 7.0625rem;
$size114: 7.125rem;
$size115: 7.1875rem;
$size116: 7.25rem;
$size117: 7.3125rem;
$size118: 7.375rem;
$size119: 7.4375rem;
$size120: 7.5rem;
$size121: 7.5625rem;
$size122: 7.625rem;
$size123: 7.6875rem;
$size124: 7.75rem;
$size125: 7.8125rem;
$size126: 7.875rem;
$size127: 7.9375rem;
$size128: 8rem;
$size129: 8.0625rem;
$size130: 8.125rem;
$size131: 8.1875rem;
$size132: 8.25rem;
$size133: 8.3125rem;
$size134: 8.375rem;
$size135: 8.4375rem;
$size136: 8.5rem;
$size137: 8.5625rem;
$size138: 8.625rem;
$size139: 8.6875rem;
$size140: 8.75rem;
$size141: 8.8125rem;
$size142: 8.875rem;
$size143: 8.9375rem;
$size144: 9rem;
$size145: 9.0625rem;
$size146: 9.125rem;
$size147: 9.1875rem;
$size148: 9.25rem;
$size149: 9.3125rem;
$size150: 9.375rem;


@font-face {font-family: 'Lato-BlackItalic'; src: url('webfonts/Lato-BlackItalic.ttf');}
@font-face {font-family: 'Lato-Bold'; src: url('webfonts/Lato-Bold.ttf');}
@font-face {font-family: 'Lato-BoldItalic'; src: url('webfonts/Lato-BoldItalic.ttf');}
@font-face {font-family: 'Lato-Hairline'; src: url('webfonts/Lato-Hairline.ttf');}
@font-face {font-family: 'Lato-HairlineItalic'; src: url('webfonts/Lato-HairlineItalic.ttf');}
@font-face {font-family: 'Lato-Italic'; src: url('webfonts/Lato-Italic.ttf');}
@font-face {font-family: 'Lato-Light'; src: url('webfonts/Lato-Light.ttf');}
@font-face {font-family: 'Lato-LightItalic'; src: url('webfonts/Lato-LightItalic.ttf');}

@font-face {
  font-family: 'Lato-Black';
  src: url('webfonts/Lato-Black.woff2') format('woff2'),
  url('webfonts/Lato-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Regular';
  src: url('webfonts/Lato-Regular.woff2') format('woff2'),
  url('webfonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MrsEavesOT-Italic';
  src: url('webfonts/361BF1_0_0.eot');
  src: url('webfonts/361BF1_0_0.eot?#iefix') format('embedded-opentype'),
  url('webfonts/361BF1_0_0.woff2') format('woff2'),
  url('webfonts/361BF1_0_0.woff') format('woff'),
  url('webfonts/361BF1_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'MrsEavesOT-Bold';
  src: url('webfonts/361BF1_1_0.eot');
  src: url('webfonts/361BF1_1_0.eot?#iefix') format('embedded-opentype'),
  url('webfonts/361BF1_1_0.woff2') format('woff2'),
  url('webfonts/361BF1_1_0.woff') format('woff'),
  url('webfonts/361BF1_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'MrsEavesOT-Roman';
  src: url('webfonts/361BF1_2_0.eot');
  src: url('webfonts/361BF1_2_0.eot?#iefix') format('embedded-opentype'),
  url('webfonts/361BF1_2_0.woff2') format('woff2'),
  url('webfonts/361BF1_2_0.woff') format('woff'),
  url('webfonts/361BF1_2_0.ttf') format('truetype');
}