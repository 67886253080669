.header {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 100;
	background-color: #fff;
	> .container {
		@media(max-width: $xl - 1){
			max-width: none;
		}
	}
}