.overlay-content {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3000;
	background-color: transparentize(black, .25);
}
/********************************************

    START AGE GATE OVERLAY

********************************************/
.gate {
	width: 100%;
	height: 100vh;
	color: black;
	position: fixed;
	z-index: 2000;
	top: 0;
	overflow: scroll;
	min-height: calc(100vh - 80px);
	font-size: 20px;
	background: white url("/public/images/xray_rose.jpg") no-repeat;
	background-size: contain;
	background-position: bottom -50px center;
	@media(min-width: $sm) {
		background-position: bottom -210px center;
		background-size: initial;
	}
}
@media (min-width: 992px) {
	.gate {
		overflow: hidden;
	}
}
.age-gate-wrapper {
	//min-height:667px;
}
.gate .logo {
	background: url("/public/images/svgs/Rosehaven_Logo1.svg") center no-repeat;
	height: 75px;
	width: 94%;
	margin: 8% 3% 0;
}
.gate .label {
	height: 150px;
	background: url("/public/images/svgs/Rosehaven_Label.svg") center no-repeat;
	width: 100%;
	background-size: 100%;
	margin: auto;
	@media (min-width: 768px) {
		height: 200px;
		width: 600px;
	}
}

.age-gate-box { /* contains text, button, rose image */
	width: 85%;
	margin: 0 auto;
	text-align: center;
	position: relative;
}
@media (min-width: 768px) {
	.age-gate-box p {
		width: 600px;
		margin: 0 auto 28px auto;
	}
}
/*.age-gate-box:after {
	content: "";
	display: block;
	background: url("/public/images/xray_rose.jpg") no-repeat;
	height: 178px;
	width: 100%;
	margin: 19% auto 0 auto;
	background-size: 100%;
}*/

.block-940 {

}
@media (min-width: 768px) {
	.age-gate-box:after {
		height: 200px;
		margin-top: 33%;
	}
}
@media (min-width: 992px) {
	.age-gate-box:after {
		height: 240px;
		margin-top: 10%;
	}
}
@media (min-width: 1200px) {
	.age-gate-box:after {
		height: 275px;
		margin-top: 4%;
	}
}
.age-gate-footer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100px;
	padding: 5px 5px 0 5px;
}
@media (min-width: 768px) {
	.age-gate-footer {
		padding-top: 22px;
	}
}